export default [
  {
    path: "/",
    text: "首頁",
  },
  {
    path: "/lunches",
    text: "找午餐",
  },
  {
    path: "/dunches",
    text: "店家廣告",
  },
  {
    path: "/iwant",
    text: "商家招募區",
  },
  {
    path: "/contact",
    text: "聯絡站長",
  },
]
